export const TEMPORARY_STANDARDS = [
  {
    id: "4d295702-2361-426a-a22e-37889943cf97",
    name: "Ades Baseline Profiles",
    type: "Geavanceerde en gekwalificeerde digitale handtekeningen",
    versions: ["Xades v2.1", "Pades v2.1", "Cades v2.2", "Asic v2.2"],
    organization: "ETSI",
  },
  {
    id: "5633d299-7053-4966-9c7f-d7ad20567b8c",
    name: "Aquo-standaard",
    type: "Watermanagement informatie",
    versions: ["Aquo 2022-06"],
    organization: "Informatiehuis Water",
  },
  {
    id: "928cf50f-f58f-448e-ab49-5bd39ec3ee71",
    name: "BWB",
    type: "Identificatie van en verwijzing naar wet- en regelgeving",
    versions: ["1.3.1"],
    organization: "KOOP",
  },
  {
    id: "a5c31f12-4380-46a1-b9e8-d342b51f3f7f",
    name: "COINS",
    type: "BIM uitwisselingsstandaard",
    versions: ["2.0"],
    organization: "BIM Loket",
  },
  {
    id: "efe5dfe7-bfba-427a-a864-23f8e71d4dc8",
    name: "Digikoppeling",
    type: "Veilige gegevensuitwisseling",
    versions: [""],
    organization: "Logius",
  },
  {
    id: "16b67289-b539-4371-99de-261c6bed0d25",
    name: "Digitoegankelijk (EN 301 549 met WCAG 2.1)",
    type: "Toegankelijkheid websites, webapplicaties en documenten",
    versions: ["3.2.1"],
    organization: "ETSI",
  },
  {
    id: "f1a202f8-4dac-4a5f-b856-3951ac8a1f35",
    name: "DKIM",
    type: "Bescherming tegen e-mailphishing",
    versions: ["RFC 6376"],
    organization: "IETF",
  },
];
