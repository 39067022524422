// extracted by mini-css-extract-plugin
export var author = "AboutTemplate-module--author--2a9bb";
export var buttonsContainer = "AboutTemplate-module--buttonsContainer--3eb20";
export var container = "AboutTemplate-module--container--d7be5";
export var content = "AboutTemplate-module--content--50656";
export var description = "AboutTemplate-module--description--50a6e";
export var layersVisual = "AboutTemplate-module--layersVisual--92c58";
export var paragraphButtons = "AboutTemplate-module--paragraphButtons--66bdf";
export var quoteWrapperContainer = "AboutTemplate-module--quoteWrapperContainer--f39c7";
export var section = "AboutTemplate-module--section--b0efb";
export var tableRow = "AboutTemplate-module--tableRow--c2308";